import ApiService from "@/core/services/api.service";

// Action Types
export const GET_ALL_BUSINESS_LINES = "get_all_business_lines";
// Mutation types
export const SET_ALL_BUSINESS_LINES = "business_lines_mutation_1";
export const SET_BUSINESS_LINES_FETCHING = "business_lines_mutation_2";
export const SET_BUSINESS_LINES_FETCHED = "business_lines_mutation_3";
export const SET_BUSINESS_LINES_ERROR = "business_lines_mutation_4";
// State
const state = {
  allBusinessLines: [],
  businessLinesFetching: true,
  businessLineError: null
};
// Getters
const getters = {
  getAllBusinessLines(state) {
    return state.allBusinessLines;
  }
};
// Actions
const actions = {
  [GET_ALL_BUSINESS_LINES](context) {
    return new Promise(resolve => {
      context.commit(SET_BUSINESS_LINES_FETCHING);
      context.commit(SET_BUSINESS_LINES_ERROR, null);
      ApiService.post("/businessLine/getAll")
        .then(({ data }) => {
          context.commit(SET_ALL_BUSINESS_LINES, data.data);
          context.commit(SET_BUSINESS_LINES_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            SET_BUSINESS_LINES_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(SET_BUSINESS_LINES_FETCHED);
          resolve(data);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_ALL_BUSINESS_LINES](state, lines) {
    state.allBusinessLines = lines;
  },
  [SET_BUSINESS_LINES_ERROR](state, err) {
    state.businessLineError = err;
  },
  [SET_BUSINESS_LINES_FETCHING](state) {
    state.businessLinesFetching = true;
  },
  [SET_BUSINESS_LINES_FETCHED](state) {
    state.businessLinesFetching = false;
  }
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
