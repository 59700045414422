import ApiService from "@/core/services/api.service";

export const CREATE_REPORT = "create_report";
export const GET_REPORTS = "get_reports";
const SET_REPORTS = "set_reports";

const state = {
  reports: [],
  reportsCount: 0
};
// Getters
const getters = {};
// Actions
const actions = {
  [GET_REPORTS]({ commit }, options) {
    const { limit = 25, offset = 0 } = options;
    return new Promise(resolve => {
      ApiService.get(`/reports/list?offset=${offset}&limit=${limit}`)
        .then(({ data: response }) => {
          console.log(
            "file: settings.module.js | line 16 | .then | response",
            response
          );
          commit(SET_REPORTS, response);

          resolve(response);
        })
        .catch(error => {
          console.error("Error while fetching report => ", error);
          resolve(null);
        });
    });
  },
  [CREATE_REPORT]({ dispatch }, payload) {
    return new Promise(resolve => {
      ApiService.get(`/reports/create?from=${payload.from}&to=${payload.to}`)
        .then(({ data: response }) => {
          console.log(
            "file: settings.module.js | line 47 | .then | response",
            response
          );
          if (response.success) {
            dispatch(GET_REPORTS, { offset: 0, limit: 25 });
            resolve(response);
          }
        })
        .catch(error => {
          console.error("file: settings.module.js | line 40 | error", error);
          resolve(null);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_REPORTS](state, response) {
    state.reports = response.reports;
    state.reportsCount = response.count;
  }
};

// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
