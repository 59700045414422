import ApiService from "@/core/services/api.service";
// Action Types
export const GET_ALL_BONUSES = "get_all_bonuses";
// Mutation types
export const SET_ALL_BONUSES = "set_all_bonuses";

export const BONUSES_FETCHING = "bonuses_mutation_1";
export const BONUSES_FETCHED = "bonuses_mutation_2";
export const BONUSES_ERROR = "bonuses_mutation_3";
// State
const state = {
  allBonuses: [],
  bonusesError: null,
  bonusesFetching: true
};
// Getters
const getters = {
  getAllBonuses(state) {
    return state.allBonuse;
  }
};
// Actions
const actions = {
  [GET_ALL_BONUSES](context) {
    context.commit(BONUSES_FETCHING);
    context.commit(BONUSES_ERROR, null);

    return new Promise(resolve => {
      ApiService.post("/bonuses/getAll")
        .then(({ data }) => {
          context.commit(SET_ALL_BONUSES, data.data);
          context.commit(BONUSES_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(BONUSES_ERROR, `${data.message} on ${data.url}`);
          context.commit(BONUSES_FETCHED);
          resolve(data);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_ALL_BONUSES](state, bonuses) {
    state.allBonuses = bonuses;
  },
  [BONUSES_FETCHING](state) {
    state.bonusesFetching = true;
  },
  [BONUSES_FETCHED](state) {
    state.bonusesFetching = false;
  },
  [BONUSES_ERROR](state, err) {
    state.bonusesError = err;
  }
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
