import ApiService from "@/core/services/api.service";

// Action Types
export const GET_ALL_LEVELS = "get_all_levels";
// Mutation types
export const SET_ALL_LEVELS = "set_all_levels";
export const LEVELS_FETCHING = "levels_mutatation_1";
export const LEVELS_FETCHED = "levels_mutation_2";
export const LEVELS_ERROR = "levels_mutation_3";
// State
const state = {
  allLevels: [],
  levelsFetching: true,
  levelsError: null
};
// Getters
const getters = {
  getAllLevels(state) {
    return state.allLevels;
  }
};
// Actions
const actions = {
  [GET_ALL_LEVELS](context) {
    context.commit(LEVELS_FETCHING);
    context.commit(LEVELS_ERROR, null);

    return new Promise(resolve => {
      ApiService.post("/levels/getAll")
        .then(({ data }) => {
          context.commit(SET_ALL_LEVELS, data.data);
          context.commit(LEVELS_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(LEVELS_FETCHED);
          context.commit(LEVELS_ERROR, `${data.message} on ${data.url}`);
          resolve(data);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_ALL_LEVELS](state, levels) {
    state.allLevels = levels;
  },
  [LEVELS_FETCHING](state) {
    state.levelsFetching = true;
  },
  [LEVELS_FETCHED](state) {
    state.levelsFetching = false;
  },
  [LEVELS_ERROR](state, err) {
    state.levelsError = err;
  }
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
