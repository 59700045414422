import ApiService from "@/core/services/api.service";
// Action Types
export const GET_ALL_ACTIVE_PRODUCTS = "get_all_active_products";
export const GET_ALL_ARCHIVED_PRODUCTS = "get_all_archived_products";
// Mutation types
export const SET_ALL_ACTIVE_PRODUCTS = "set_all_active_products";
export const SET_ALL_ARCHIVED_PRODUCTS = "set_all_archived_products";
export const SET_ALL_PRODUCTS_COUNT = "set_all_products_count";
export const SET_ALL_PRODUCTS_ERROR = "set_all_products_error";

export const SET_ARCHIVED_PRODUCTS_COUNT = "set_archived_products_count";
/* loader */
export const SET_PRODUCTS_LOADING = "set_products_loading";
export const SET_PRODUCTS_LOADED = "set_products_loaded";
export const SET_ARCHIVED_PRODUCTS_LOADING = "set_archived_products_loading";
export const SET_ARCHIVED_PRODUCTS_LOADED = "set_archived_products_loaded";

export const SET_ARCHIVED_PRODUCTS_ERROR = "set_archived_products_error";

// State
const state = {
  allProducts: [],
  productsCount: 0,
  activeProductsError: null,

  fetchingProducts: true,
  archivedProducts: [],
  archivedProductsCount: 0,
  archivedProductsError: null,
  fetchingArchivedProducts: true
};
// Getters
const getters = {
  getAllProducts(state) {
    return state.allProcuts;
  },
  getProductsCount(state) {
    return state.productsCount;
  },
  getFetchingProducts(state) {
    return state.fetchingProducts;
  }
};
// Actions
const actions = {
  [GET_ALL_ACTIVE_PRODUCTS]({ commit }, options = {}) {
    // ApiService.setHeader();
    commit(SET_PRODUCTS_LOADING);
    const { limit } = options;
    if (!limit) {
      options.limit = [0, 10];
    }
    return new Promise(resolve => {
      ApiService.post(
        `/products/getAllProductsAdmin/${options.limit[0]}/${options.limit[1]}`,
        options
      )
        .then(({ data }) => {
          commit(SET_ALL_ACTIVE_PRODUCTS, data.data.products);
          commit(SET_ALL_PRODUCTS_COUNT, data.data.productsCount);
          commit(SET_PRODUCTS_LOADED);
          resolve(data);
        })
        .catch(({ data }) => {
          commit(SET_ALL_PRODUCTS_ERROR, `${data.message} on ${data.url}`);
          resolve(data);
        });
    });
  },
  [GET_ALL_ARCHIVED_PRODUCTS]({ commit }, options = {}) {
    // ApiService.setHeader();
    commit(SET_ARCHIVED_PRODUCTS_LOADING);
    const { limit } = options;
    if (!limit) {
      options.limit = [0, 10];
    }
    options.archived = 1;
    return new Promise(resolve => {
      ApiService.post(
        `/products/getAllProductsAdmin/${options.limit[0]}/${options.limit[1]}`,
        options
      )
        .then(({ data }) => {
          commit(SET_ALL_ARCHIVED_PRODUCTS, data.data.products);
          commit(SET_ARCHIVED_PRODUCTS_COUNT, data.data.productsCount);
          commit(SET_ARCHIVED_PRODUCTS_LOADED);
          resolve(data);
        })
        .catch(({ data }) => {
          commit(SET_ARCHIVED_PRODUCTS_ERROR, `${data.message} on ${data.url}`);
          resolve(data);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_ALL_ACTIVE_PRODUCTS](state, products) {
    state.allProducts = [...products];
  },
  [SET_ALL_PRODUCTS_COUNT](state, products) {
    state.productsCount = products;
  },
  [SET_ALL_ARCHIVED_PRODUCTS](state, products) {
    state.archivedProducts = [...products];
  },
  [SET_ARCHIVED_PRODUCTS_COUNT](state, products) {
    state.archivedProductsCount = products;
  },
  [SET_PRODUCTS_LOADING](state) {
    state.fetchingProducts = true;
  },
  [SET_PRODUCTS_LOADED](state) {
    state.fetchingProducts = false;
  },
  [SET_ARCHIVED_PRODUCTS_LOADING](state) {
    state.fetchingArchivedProducts = true;
  },
  [SET_ARCHIVED_PRODUCTS_LOADED](state) {
    state.fetchingArchivedProducts = false;
  },
  [SET_ARCHIVED_PRODUCTS_ERROR](state, err) {
    state.archivedProductsError = err;
  },
  [SET_ALL_PRODUCTS_ERROR](state, err) {
    state.activeProductsError = err;
  }
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
