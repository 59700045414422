import ApiService from "@/core/services/api.service";

// import JwtService from "@/core/services/jwt.service";
// Action Types
// Get stores
export const GET_STORES = "stores_action_0";
export const GET_ALL_STORES = "stores_action1";
export const GET_ALL_ARCHIVED_STORES = "stores_action2";
export const GET_ALL_ACTIVEV_STORES = "stores_action3";
export const ARCHIVE_STORE = "stores_action_4";
export const UNARCHIVE_STORE = "stores_action_5";

// Mutation types
// Set stores fetching
export const SET_STORES = "stores_mutation_1";
export const SET_FETCHING = "stores_mutation_2";
/* Active Store methods */
export const SET_ALL_STORES = "stores_mutation1";
export const SET_ALL_ACTIVE_STORES_LENGTH = "stores_mutation2";

export const SET_STORE_FETCHING = "stores_mutation3";
export const SET_STORE_FETCHED = "stores_mutation4";
export const SET_ACTIVE_STORE_ERROR = "stores_mutation5";

/* Archived stores methods */
export const SET_ARCHIVE_STORES = "stores_mutation6";
export const SET_ARCHIVE_STORES_LENGTH = "stores_mutation7";

export const SET_ARCHIVE_STORE_FETCHING = "stores_mutation8";
export const SET_ARCHIVE_STORE_FETCHED = "stores_mutation9";
export const SET_ARCHIVE_STORE_ERROR = "stores_mutation10";
/* ActiveV stores methods */
export const SET_ACTIVEV_STORES = "stores_mutation11";
export const SET_ACTIVEV_STORES_LENGTH = "stores_mutation12";

export const SET_ACTIVEV_STORE_FETCHING = "stores_mutation13";
export const SET_ACTIVEV_STORE_FETCHED = "stores_mutation14";
export const SET_ACTIVEV_STORE_ERROR = "stores_mutation15";

// State
const state = {
  active: {
    stores: [],
    count: 0,
    fetching: false,
    error: null
  },
  archived: {
    stores: [],
    count: 0,
    fetching: false,
    error: null
  },
  contracted: {
    stores: [],
    count: 0,
    fetching: false,
    error: null
  }
};
// Getters
const getters = {
  getAllStores(state) {
    return state.allStores;
  },
  getStoresFetching(state) {
    return state.storesFetching;
  },
  gettArchivedStores(state) {
    return state.allStores;
  },
  getArchivedStoresFetching(state) {
    return state.storesFetching;
  },
  gettActiveVStores(state) {
    return state.activeVStores;
  },
  getActiveVStoresFetching(state) {
    return state.activeVStoresFetching;
  },
  getAllSubzoneStores(state) {
    return state.subzoneStores;
  }
};
// Actions
const actions = {
  [ARCHIVE_STORE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.post("/store/archiveStore", { storeId: id })
        .then(({ data: res, error }) => {
          if (res.success) {
            resolve("archived successfully.");
          } else {
            reject(`Archive Error: ${error.message}`);
          }
        })
        .catch(({ data }) => {
          reject(`Archive Error: ${data.message} on ${data.url}`);
        });
    });
  },
  [UNARCHIVE_STORE](context) {
    console.log("file: stores.module.js | line 131 | context", context);
  },
  [GET_STORES]({ commit }, options = {}) {
    const { type } = options;
    const types = ["active", "archived", "contracted"];
    if (!type || !types.includes(type)) {
      return { error: "type is not provided or valid" };
    }
    const {
      offset = 0,
      limit = 10,
      search = "",
      sort = "-createdAt"
    } = options;
    commit(SET_STORES, { type, stores: [] });
    commit(SET_FETCHING, { type, val: true });
    return new Promise(resolve => {
      ApiService.post(`/store/getStores/${offset}/${limit}`, {
        type,
        sort,
        search
      })
        .then(({ data }) => {
          const { count = 0, stores = [] } = data;
          commit(SET_STORES, { type, count, stores });
          commit(SET_FETCHING, { type, val: false });
          resolve(data);
        })
        .catch(error => {
          const { data } = error;
          commit(SET_FETCHING, { type, val: false });
          commit(SET_ACTIVE_STORE_ERROR, `${data.message} on ${data.url}`);
          resolve(data);
        });
    });
  },
  [GET_ALL_STORES](context, options = {}) {
    context.commit(SET_ACTIVE_STORE_ERROR, null);
    context.commit(SET_STORE_FETCHING);
    let { limit } = options;
    options.archived = 0;
    if (limit === undefined) {
      limit = [0, 10];
    }
    return new Promise(resolve => {
      ApiService.post(`/store/getAllStores/${limit[0]}/${limit[1]}`, options)
        .then(res => {
          const { data } = res;
          context.commit(SET_ALL_STORES, data.data.stores);
          context.commit(SET_ALL_ACTIVE_STORES_LENGTH, data.data.count);
          context.commit(SET_STORE_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            SET_ACTIVE_STORE_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(SET_STORE_FETCHED);
          resolve(data);
        });
    });
  },
  [GET_ALL_ARCHIVED_STORES](context, options = {}) {
    context.commit(SET_ARCHIVE_STORE_ERROR, null);
    context.commit(SET_ARCHIVE_STORE_FETCHING);
    context.commit(SET_ARCHIVE_STORES, []);
    context.commit(SET_ARCHIVE_STORES_LENGTH, 0);

    let { limit } = options;
    options.archived = 1;
    if (limit === undefined) {
      limit = [0, 10];
    }

    return new Promise(resolve => {
      ApiService.post(`/store/getAllStores/${limit[0]}/${limit[1]}`, options)
        .then(res => {
          const { data } = res;
          context.commit(SET_ARCHIVE_STORES, data.data.stores);
          context.commit(SET_ARCHIVE_STORES_LENGTH, data.data.count);
          context.commit(SET_ARCHIVE_STORE_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            SET_ARCHIVE_STORE_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(SET_ARCHIVE_STORE_FETCHED);
          resolve(data);
        });
    });
  }
};

// Mutations
const mutations = {
  [SET_ALL_STORES](state, stores) {
    state.allStores = stores;
  },
  [SET_ALL_ACTIVE_STORES_LENGTH](state, stores) {
    state.allStoresLength = stores;
  },
  [SET_STORE_FETCHING](state) {
    state.storesFetching = true;
  },
  [SET_STORE_FETCHED](state) {
    state.storesFetching = false;
  },
  [SET_ACTIVE_STORE_ERROR](state, error) {
    state.activeStoresError = error;
  },

  [SET_ARCHIVE_STORES](state, stores) {
    state.archivedStores = stores;
  },
  [SET_ARCHIVE_STORES_LENGTH](state, stores) {
    state.archivedStoresLength = stores;
  },
  [SET_ARCHIVE_STORE_FETCHING](state) {
    state.archivedStoresFetching = true;
  },
  [SET_ARCHIVE_STORE_FETCHED](state) {
    state.archivedStoresFetching = false;
  },
  [SET_ARCHIVE_STORE_ERROR](state, error) {
    state.archiveStoresError = error;
  },

  [SET_STORES](state, { type, count, stores }) {
    state[type].stores = stores;
    if (count) {
      state[type].count = count;
    }
  },
  [SET_FETCHING](state, { type, val }) {
    state[type].fetching = val;
  }
  // subzones below
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
