import ApiService from "@/core/services/api.service";
// Action Types
// Getter actions
export const GET_ALL_DEALS = "deals_action_1";

// Setter actions
export const SET_ALL_DEALS = "deals_mutation_1";
export const SET_DEALS_COUNT = "deals_mutation_2";
export const SET_DEALS_FETCHING = "deals_mutation_3";

// State
const state = {
  allDeals: [],
  totalDeals: 0,
  dealsFetching: true
};
// Getters
const getters = {
  getAllDeals(state) {
    return state.allDeals;
  }
};
// Actions
const actions = {
  [GET_ALL_DEALS]({ commit }, payload = {}) {
    commit(SET_ALL_DEALS, []);
    commit(SET_DEALS_COUNT, 0);
    commit(SET_DEALS_FETCHING, true);
    return new Promise(resolve => {
      ApiService.post(
        `/deals/getAll/${payload.offset || 0}/${payload.limit || 10}`,
        payload
      )
        .then(({ data: result }) => {
          console.log(
            "file: deals.module.js | line 44 | .then | result",
            result
          );
          commit(SET_ALL_DEALS, result.data.deals);
          commit(SET_DEALS_COUNT, result.data.count);
          commit(SET_DEALS_FETCHING, false);
          resolve(result);
        })
        .catch(({ data: result }) => {
          commit(SET_DEALS_FETCHING, false);
          resolve(result);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_ALL_DEALS](state, deals) {
    state.allDeals = deals;
  },
  [SET_DEALS_COUNT](state, count) {
    state.totalDeals = count;
  },
  [SET_DEALS_FETCHING](state, status) {
    state.dealsFetching = status;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
