import Vue from "vue";
import moment from "moment-timezone";
import HumanizeDuration from "humanize-duration";
import VueCookies from "vue-cookies";
import VueSession from "vue-session";
import PerfectScrollbar from "perfect-scrollbar";
import VueClipboard from "vue-clipboard2";
import VueTimeago from "vue-timeago";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
// import MockService from "./core/mock/mock.service";
// import { VERIFY_AUTH } from "./core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "./core/plugins/vue-tel";
import "./core/plugins/vue2-google-maps";
import "./core/plugins/vue-meta";

// import "vue-tel-input/dist/vue-tel-input.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(VueCookies);
Vue.use(VueSession, {
  persist: true
});

Vue.config.productionTip = false;
window.PerfectScrollbar = PerfectScrollbar;
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.prototype.$moment = moment;
Vue.prototype.$humanize = HumanizeDuration;
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    "zh-CN": require("date-fns/locale/zh_cn"),
    ja: require("date-fns/locale/ja")
  }
});

// vuemeta

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

new Vue({
  router,
  store,
  i18n,
  vuetify,

  render: h => h(App)
}).$mount("#app");
