const ID_TOKEN_KEY = "id_token";
const ID_USER_KEY = "id_user";
const EMAIL_USER_KEY = "email_user";
export const getToken = () => window.localStorage.getItem(ID_TOKEN_KEY);

export const saveToken = (token, id) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_USER_KEY, id);
};

export const getUserId = () => window.localStorage.getItem(ID_USER_KEY);

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_USER_KEY);
  window.localStorage.removeItem(EMAIL_USER_KEY);
};
/// adding my own code below for Email  connected to QuickUser.vue ///
export const saveEmail = email => {
  window.localStorage.setItem(EMAIL_USER_KEY, email);
};
export const getEmail = () => window.localStorage.getItem(EMAIL_USER_KEY);
export const destroyEmail = () => {
  window.localStorage.removeItem(EMAIL_USER_KEY);
};

/// /above code added for email  by Umar////
export default {
  getToken,
  saveToken,
  destroyToken,
  getUserId,
  saveEmail,
  getEmail,
  destroyEmail
};
