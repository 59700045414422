import ApiService from "../api.service";
// Action Types
export const FETCH_ORDER_HISTORY = "FETCH_ORDER_HISTORY";
const TOGGLE = "set_fetching";
const SET_ORDERS = "set_orders";
// State
const state = {
  count: 0,
  fetching: false,
  orders: []
};
const getters = {};
const actions = {
  [FETCH_ORDER_HISTORY]({ commit }, limits) {
    commit(TOGGLE, true);
    return new Promise(resolve => {
      commit(SET_ORDERS, { list: [], count: 0 });
      ApiService.post(`/order/dashboardOrderHistory/${limits[0]}/${limits[1]}`)
        .then(({ data: response }) => {
          commit(SET_ORDERS, { list: response.orders, count: response.count });
          commit(TOGGLE, false);
          resolve(response);
        })
        .catch(error => {
          console.log("file: activeOrders.module.js | line 26 | error", error);
          commit(TOGGLE, false);
        });
    });
  }
};

const mutations = {
  [TOGGLE](state, val) {
    state.fetching = val;
  },
  [SET_ORDERS](state, { list = [], count = 0 }) {
    state.count = count;
    state.orders = list;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
