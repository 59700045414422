/* eslint-disable no-unused-vars */
import ApiService from "@/core/services/api.service";

// Action Types
export const GET_ACCETABLE_RANGE = "get_accetable_range";
export const GET_SALES_TAX = "get_sales_tax";
export const GET_ACTIVE_ZIPCODES = "get_active_zipcodes";
export const UPDATE_TAX = "update_tax";
export const UPDATE_ZIPCODE = "update_zipcode";
export const UPDATE_UPCHARGE = "update_upcharge";
export const REMOVE_TAX = "remove_tax";
export const REMOVE_ZIPCODE = "remove_zipcode";
export const GET_UPCHARGE = "get_upcharge";
// Mutation Types
const ACCEPTABLE_RANGE = "set_accetable_range";
const SET_UPCHARGE = "set_upcharge";
const SET_ZIPCODES = "set_zipcodes";
const SALES_TAX = "set_sales_taxes";
const FETCHING_SALES_TAX = "fetching_sales_taxes";
const FETCHING_ZIPCODES = "fetching_zipcodes";
const SHIFT_TAX = "shift_new_tax";
const SHIFT_ZIPCODE = "shift_zipcode";
const POP_TAX = "pop_tax";
const POP_ZIPCODE = "pop_zipcode";

const state = {
  acceptableRange: {
    id: 0,
    value: null
  },
  taxes: [],
  zipcodes: [],
  fetchingZipcodes: false,
  fetchingTaxes: false,
  upCharge: {
    id: 0,
    value: null
  }
};
// Getters
const getters = {};
// Actions
const actions = {
  [GET_ACTIVE_ZIPCODES]({ commit }, payload = {}) {
    const { limit = 10, offset = 0 } = payload;
    commit(FETCHING_ZIPCODES, true);
    return new Promise(resolve => {
      ApiService.get(`/settings/activeZipcodes/${offset}/${limit}`).then(
        ({ data }) => {
          console.log("file: settings-us.module.js | line 46 | data", data);
          const { settings } = data;
          commit(SET_ZIPCODES, settings);
          commit(FETCHING_ZIPCODES, false);
          resolve(settings);
        }
      );
    });
  },
  [GET_UPCHARGE]({ commit }) {
    return new Promise(resolve => {
      ApiService.get("/settings/getSetting/upCharge").then(({ data }) => {
        const { setting } = data;
        commit(SET_UPCHARGE, setting);
        resolve(setting);
      });
    });
  },
  [GET_ACCETABLE_RANGE]({ commit }) {
    return new Promise(resolve => {
      ApiService.get("/settings/getSetting/acceptableRange").then(
        ({ data }) => {
          const { setting } = data;
          commit(ACCEPTABLE_RANGE, setting);
          resolve(true);
        }
      );
    });
  },
  [GET_SALES_TAX]({ commit }, payload = {}) {
    const { limit = 10, offset = 0 } = payload;
    commit(FETCHING_SALES_TAX, true);
    return new Promise(resolve => {
      ApiService.get(`/settings/getSalesTaxes/${offset}/${limit}`)
        .then(({ data }) => {
          commit(FETCHING_SALES_TAX, false);
          commit(SALES_TAX, data.taxes);
          resolve(true);
        })
        .catch(error => {
          console.error(
            "file: settings-us.module.js | line 39 | ApiService.get | error",
            error
          );
          commit(FETCHING_SALES_TAX, false);
          resolve(false);
        });
    });
  },
  [UPDATE_UPCHARGE]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("/settings/editSetting", payload)
        .then(({ data: response }) => {
          if (response.success === 1) {
            commit(SET_UPCHARGE, response.setting);
            resolve({ error: null });
          } else {
            resolve({ error: response.error.message });
          }
        })
        .catch(error => {
          console.log("file: settings-us.module.js | line 61 | error", error);
          resolve({ error });
        });
    });
  },
  [UPDATE_ZIPCODE]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("/settings/addZipcode", payload)
        .then(({ data: response }) => {
          console.log(
            "file: settings-us.module.js | line 131 | .then | response",
            response
          );
          if (response.success === 1) {
            commit(SHIFT_ZIPCODE, response.setting);
            resolve({ error: null });
          } else {
            resolve({ error: response.error.message });
          }
        })
        .catch(error => {
          console.log("file: settings-us.module.js | line 61 | error", error);
          resolve({ error });
        });
    });
  },
  [UPDATE_TAX]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("/settings/addTaxes", payload)
        .then(({ data: response }) => {
          if (response.success === 1) {
            commit(SHIFT_TAX, { tax: response.setting, isNew: response.isNew });
            resolve({ error: null });
          } else {
            resolve({ error: response.error.message });
          }
        })
        .catch(error => {
          console.log("file: settings-us.module.js | line 61 | error", error);
          resolve({ error });
        });
    });
  },
  [REMOVE_TAX]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("/settings/removeTax", payload)
        .then(({ data: response }) => {
          if (response.success === 1) {
            commit(POP_TAX, payload);
            resolve({ error: null });
          } else {
            resolve({ error: response.error.message });
          }
        })
        .catch(error => {
          console.log("file: settings-us.module.js | line 61 | error", error);
          resolve({ error });
        });
    });
  },
  [REMOVE_ZIPCODE]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("/settings/removeZipcode", payload)
        .then(({ data: response }) => {
          if (response.success === 1) {
            commit(POP_ZIPCODE, payload);
            resolve({ error: null });
          } else {
            resolve({ error: response.error.message });
          }
        })
        .catch(error => {
          console.log("file: settings-us.module.js | line 61 | error", error);
          resolve({ error });
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_UPCHARGE](state, setting) {
    state[setting.name] = {
      id: setting.id,
      value: setting.value
    };
  },
  [ACCEPTABLE_RANGE](state, setting) {
    state[setting.name] = {
      id: setting.id,
      value: setting.value
    };
  },
  [FETCHING_SALES_TAX](state, val) {
    state.fetchingTaxes = val;
  },
  [SALES_TAX](state, taxes) {
    state.taxes = taxes;
  },
  [SET_ZIPCODES](state, val) {
    state.zipcodes = val;
  },
  [FETCHING_ZIPCODES](state, val) {
    state.fetchingZipcodes = val;
  },
  [SHIFT_TAX](state, payload) {
    const { isNew = false, tax = null } = payload;
    const { taxes: temp } = state;
    if (isNew) {
      temp.push(tax);
    } else {
      const idx = temp.findIndex(t => t.settingId === tax.settingId);
      temp[idx].tax = tax.tax;
      temp[idx].zipCode = tax.zipCode;
    }
    state.taxes = temp;
  },
  [SHIFT_ZIPCODE](state, payload) {
    console.log("file: settings-us.module.js | line 224 | payload", payload);
    const { isNew = false, setting = null } = payload;
    const { zipcodes: temp } = state;
    if (isNew) {
      temp.push(setting);

      state.zipcodes = temp;
    } else {
      const idx = temp.findIndex(t => t.settingId === setting.settingId);
      console.log("file: settings-us.module.js | line 237 | idx", idx);
      Object.keys(setting).forEach(k => {
        state.zipcodes[idx][k] = setting[k];
      });
    }
  },
  [POP_TAX](state, payload) {
    const { taxes: temp } = state;
    state.taxes = temp.filter(t => t.settingId !== payload.settingId);
  },
  [POP_ZIPCODE](state, payload) {
    const { zipcodes: temp } = state;
    state.zipcodes = temp.filter(t => t.settingId !== payload.settingId);
  }
};

// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
