import ApiService from "@/core/services/api.service";

// Action Types
export const GET_ALL_NOTIFICATIONS = "get_all_notifications";
export const GET_ARCHIVED_NOTIFICATIONS = "get_archived_notifications";
// Mutation types
export const SET_ALL_NOTIFICATIONS = "set_all_notifications";
export const SET_ARCHIVED_NOTIFICATIONS = "set_archived_notifications";
export const NOTIFICATIONS_FETCHING = "notifications_fetching";
export const NOTIFICATIONS_FETCHED = "notifications_fetched";
export const NOTIFICATIONS_ERROR = "notifications_error";
// State
const state = {
  allNotifications: [],
  archivedNotifications: [],
  notificationsFetching: true,
  notificationsError: null
};
// Getters
const getters = {
  getAllNotifications(state) {
    return state.allNotifications;
  }
};
// Actions
const actions = {
  [GET_ALL_NOTIFICATIONS](context) {
    context.commit(NOTIFICATIONS_FETCHING);
    return new Promise(resolve => {
      ApiService.post("/notifications/getAll", { archived: false })
        .then(({ data }) => {
          context.commit(SET_ALL_NOTIFICATIONS, data.notifications);
          context.commit(NOTIFICATIONS_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(NOTIFICATIONS_ERROR, `${data.message} on ${data.url}`);
          context.commit(NOTIFICATIONS_FETCHED);
          resolve(data);
        });
    });
  },
  [GET_ARCHIVED_NOTIFICATIONS](context) {
    context.commit(NOTIFICATIONS_FETCHING);
    return new Promise(resolve => {
      ApiService.post("/notifications/getAll", { archived: true })
        .then(({ data }) => {
          context.commit(SET_ARCHIVED_NOTIFICATIONS, data.notifications);
          context.commit(NOTIFICATIONS_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(NOTIFICATIONS_ERROR, `${data.message} on ${data.url}`);
          context.commit(NOTIFICATIONS_FETCHED);
          resolve(data);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_ALL_NOTIFICATIONS](state, notifications) {
    state.allNotifications = notifications;
  },
  [SET_ARCHIVED_NOTIFICATIONS](state, notifications) {
    state.archivedNotifications = notifications;
  },
  [NOTIFICATIONS_FETCHING](state) {
    state.notificationsFetching = true;
  },
  [NOTIFICATIONS_FETCHED](state) {
    state.notificationsFetching = false;
  },
  [NOTIFICATIONS_ERROR](state, err) {
    state.notificationsError = err;
  }
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
