/* eslint-disable no-unused-vars */
import ApiService from "@/core/services/api.service";

// Action Types
export const GET_ALL_ACTIVE_RIDERS = "rider_action_1";
export const GET_ALL_ARCHIVED_RIDERS = "rider_action_2";
export const UPDATE_RIDER_INFO = "rider_action_3";
export const UPDATE_RIDER_IMAGE = "rider_action_4";
export const UPDATE_RIDER_DOCUMENTS = "rider_action_5";
// Mutation types
export const SET_ALL_ACTIVE_RIDERS = "rider_mutation_1";
export const SET_ALL_ACTIVE_RIDERS_LENGTH = "rider_mutation_2";

export const SET_RIDERS_FETCHING = "rider_mutation_3";
export const SET_RIDERS_FETCHED = "rider_mutation_4";

export const SET_ALL_ARCHIVED_RIDERS = "rider_mutation_5";
export const SET_ALL_ARCHIVED_RIDERS_LENGTH = "rider_mutation_6";
export const SET_RIDERS_ARCHIVED_FETCHING = "rider_mutation_7";
export const SET_RIDERS_ARCHIVED_FETCHED = "rider_mutation_8";

export const SET_RIDERS_ARCHIVED_ERROR = "rider_mutation_9";
export const SET_RIDERS_ACTIVE_ERROR = "rider_mutation_10";

// State
const state = {
  activeRiders: [],
  archivedRiders: [],
  archivedCount: 0,
  riderAmount: 0,
  fetchingArchivedRiders: true,
  ridersFetching: true,
  activeRidersError: null,
  archivedRidersError: null
};
// Getters
const getters = {
  allRiders(state) {
    return state.activeRiders;
  },
  totalRiderAmount(state) {
    return state.riderAmount;
  },
  getRidersFetching(state) {
    return state.ridersFetching;
  },
  archivedRiders(state) {
    return state.archivedRiders;
  },
  archivedRiderAmount(state) {
    return state.archivedCount;
  },
  archivedRidersFetching(state) {
    return state.fetchingArchivedRiders;
  }
};
// Actions
const actions = {
  [GET_ALL_ACTIVE_RIDERS](context, options = {}) {
    context.commit(SET_RIDERS_ACTIVE_ERROR, null);
    context.commit(SET_RIDERS_FETCHING);

    const { limit } = options;
    options.archived = 0;
    return new Promise(resolve => {
      ApiService.post(`/rider/getAllRiders/${limit[0]}/${limit[1]}`, options)
        .then(({ data }) => {
          context.commit(SET_ALL_ACTIVE_RIDERS, data.data.riders);
          context.commit(SET_ALL_ACTIVE_RIDERS_LENGTH, data.data.count);
          context.commit(SET_RIDERS_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            SET_RIDERS_ACTIVE_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(SET_RIDERS_FETCHED);
          resolve(data);
        });
    });
  },
  [GET_ALL_ARCHIVED_RIDERS](context, options = {}) {
    context.commit(SET_RIDERS_ARCHIVED_ERROR, null);
    context.commit(SET_RIDERS_ARCHIVED_FETCHING);
    const { limit } = options;
    options.archived = 1;
    return new Promise(resolve => {
      ApiService.post(`/rider/getAllRiders/${limit[0]}/${limit[1]}`, options)
        .then(({ data }) => {
          context.commit(SET_ALL_ARCHIVED_RIDERS, data.data.riders);
          context.commit(SET_ALL_ARCHIVED_RIDERS_LENGTH, data.data.count);
          context.commit(SET_RIDERS_ARCHIVED_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            SET_RIDERS_ARCHIVED_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(SET_RIDERS_ARCHIVED_FETCHED);
          resolve(data);
        });
    });
  },
  [UPDATE_RIDER_INFO](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/rider/editRider", payload)
        .then(({ data: response }) => {
          if (response.success === 1) {
            resolve(null);
          } else {
            resolve(response.error.message);
          }
        })
        .catch(error => {
          console.error("file: riders.module.js | line 111 | error", error);
          resolve(error);
        });
    });
  },
  [UPDATE_RIDER_IMAGE](context, payload) {
    return new Promise(resolve => {
      ApiService.setHeader("content-type", "multipart/form-data");
      ApiService.post("/rider/uploadImage", payload)
        .then(({ data: response }) => {
          ApiService.setHeader("content-type", "Application/json");
          if (response.success === 1) {
            resolve(null);
          } else {
            resolve(response.error.message);
          }
        })
        .catch(error => {
          ApiService.setHeader("content-type", "Application/json");
          console.error("file: riders.module.js | line 111 | error", error);
          resolve(error);
        });
    });
  },
  [UPDATE_RIDER_DOCUMENTS](context, payload) {
    return new Promise(resolve => {
      ApiService.setHeader("content-type", "multipart/form-data");
      ApiService.post("/rider/uploadDocument", payload)
        .then(({ data: response }) => {
          ApiService.setHeader("content-type", "Application/json");
          if (response.success === 1) {
            resolve(null);
          } else {
            resolve(response.error.message);
          }
        })
        .catch(error => {
          ApiService.setHeader("content-type", "Application/json");
          console.error("file: riders.module.js | line 111 | error", error);
          resolve(error);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_ALL_ACTIVE_RIDERS](state, riders) {
    state.activeRiders = riders;
  },
  [SET_ALL_ACTIVE_RIDERS_LENGTH](state, riders) {
    state.riderAmount = riders;
  },

  [SET_RIDERS_FETCHING](state) {
    state.ridersFetching = true;
  },
  [SET_RIDERS_FETCHED](state) {
    state.ridersFetching = false;
  },
  [SET_ALL_ARCHIVED_RIDERS](state, riders) {
    state.archivedRiders = riders;
  },
  [SET_ALL_ARCHIVED_RIDERS_LENGTH](state, riders) {
    state.archivedCount = riders;
  },

  [SET_RIDERS_ARCHIVED_FETCHING](state) {
    state.fetchingArchivedRiders = true;
  },
  [SET_RIDERS_ARCHIVED_FETCHED](state) {
    state.fetchingArchivedRiders = false;
  },
  [SET_RIDERS_ACTIVE_ERROR](state, err) {
    state.activeRidersError = err;
  },
  [SET_RIDERS_ARCHIVED_ERROR](state, err) {
    state.archivedRidersError = err;
  }
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations
};
